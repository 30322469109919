import React from "react";
import "./App.scss";
import quizData from "./quiz_data.json";

import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="main">
      <Outlet />
    </div>
  );
}

export default App;
