import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import getLocalStorage from "../../helpers/getLocalStorage";
import setLocalStorage from "../../helpers/setLocalStorage";
import styles from './index.module.scss';

const Stepper = ({data}) => {
  const [steps] = useState(Array.from('x'.repeat(14)).map((el, idx) => ({ stepNo: idx + 1 })));
  const [selectedStep, setSelectedStep] = useState(1);
  const [maxStep, setMaxStep] = useState(getLocalStorage("quiz_maxStep") || 1);

  const location = useLocation();


  React.useEffect(() => {
    const maxStep = getLocalStorage("quiz_maxStep") || 1;
    if (selectedStep > maxStep) {
      setLocalStorage("quiz_maxStep", selectedStep)
    }
  }, [selectedStep]);

  React.useEffect(() => {
    const hash = location.hash.replace("#", "");
    const routeIndex = data.findIndex(({path}) => path === hash);
    if (routeIndex === -1) return;
    setSelectedStep(routeIndex+1)

    const maxStep = getLocalStorage("quiz_maxStep") || 1;
    if (!maxStep) setLocalStorage("quiz_maxStep", 1);
    setMaxStep(Number(maxStep));
    setLocalStorage("quiz_maxStep", maxStep)
  }, [location, data]);

  const stepperClicked = (step) => {
    if (step.stepNo > maxStep) return;
    setSelectedStep(step.stepNo)
  };

  return <div className={`${styles.stepperContainer} flex items-center w-full`}>
    {steps.map((step, _idx) => (
      <div className={`${styles.stepperWrapper} ${selectedStep === step.stepNo ? `!w-[24%]` : ''}`}>
      <Link to={step.stepNo > maxStep ? "" : `#${data[_idx].path}`}>
        <div className={`${step.stepNo <= maxStep ? "cursor-pointer" : "cursor-not-allowed"} ${styles.stepper} ${selectedStep === step.stepNo ? `${styles.ivory}` : ''}`} onClick={() => stepperClicked(step)}></div>
      </Link>
      </div>
    ))}
  </div>;
};

export default Stepper;
