import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./index.module.scss";

import Stepper from "../Stepper";
import AnimatedOutlet from "../AnimatedOutlet";
import { motion } from "framer-motion";
import QuizQuestion from "../QuizQuestion";

import quizData from "../../quiz_data.json";
import { getQuestionNo } from "../..";

const QuizLayout = ({ data }) => {
  const location = useLocation();

  React.useEffect(() => {
    console.log("loc change", location);
    const hash = location.hash.replace("#", "");
    const scrollContainerElem = document.getElementById("#scrollable-container");
    const elem = document.getElementById(hash);
    if (!elem) return;
    const leftOffset = elem?.offsetLeft;
    console.log(leftOffset)
    scrollContainerElem.scrollTo({ top: 0, left: leftOffset, behavior: 'smooth' }); 
  }, [location]);
  return (
    <div className={styles.base}>
      <h3 id={styles.allyTitleMobile} className={styles.miniTitle}>Are You a Diverse Hair & Makeup Ally?</h3>
      <div className="flex items-center justify-center">
        <Stepper data={data} />
      </div>
      <div className={styles.underStepper}>
        <h3 id={styles.bbrQuizTitle} className={styles.miniTitle}>BBR QUIZ</h3>
        <h3 id={styles.allyTitleDesktop} className={styles.miniTitle}>
          Are You a Diverse Hair & Makeup Ally?
        </h3>
      </div>
      {/* <motion.div
        layout
        // transition={{ duration: 1.3 }}
        key={location.pathname}
        initial={{ x: 150 }}
        animate={{ x: 0 }}
        exit={{ x: 150 }}
        // initial={{ opacity: 1, x: 150 }}
        // animate={{ opacity: 1, x: 0 }}
        // exit={{ opacity: 1  , x: 150 }}
      > */}
      {/* <Outlet /> */}
      <div className="flex overflow-x-hidden overflow-y-hidden grow scrollable" id="#scrollable-container" style={{     minWidth: "100vw"}}>
      {quizData.questions.map((q, idx, self) => (
        <QuizQuestion
          title={q.title}
          answers={q.answers}
          questionNo={getQuestionNo(idx + 1)}
          path={q.path}
          nextQuestionPath={self[idx+1]?.path ?? "complete"}
        />
      ))}
      </div>
      {/* </motion.div> */}
      {/* <AnimatedOutlet /> */}
    </div>
  );
};

export default QuizLayout;
