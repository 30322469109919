import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import QuizLayout from "./components/QuizLayout";
import QuizQuestion from "./components/QuizQuestion";
import Landing from "./components/Landing";
import Completed from "./components/Completed";
import ErrorPage from "./error-page";
import quizData from "./quiz_data.json";

import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";

export const getQuestionNo = (questionNo) => {
  if (questionNo < 10) return `0${questionNo}`;
  return `${questionNo}`;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/completed",
        element: <Completed />,
      },
    ],
  },
  {
    path: "quiz",
    element: <QuizLayout data={quizData.questions} />,
    errorElement: <ErrorPage />,
    // children: quizData.questions.map((q, idx) => ({
    //   path: idx === 0 ? "/quiz" : `/quiz/${q.path}`,
    //   element: (
    //     <QuizQuestion
    //       title={q.title}
    //       answers={q.answers}
    //       questionNo={getQuestionNo(idx + 1)}
    //     />
    //   ),
    // })),
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
