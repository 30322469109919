import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

import WaveCompleted from "../../images/wave-completed.svg";

import getLocalStorage from "../../helpers/getLocalStorage";
import setLocalStorage from "../../helpers/setLocalStorage";

import quizData from "../../quiz_data.json";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const questionsCount = 14;
const Completed = () => {
  const API_URL = "https://quiz-api.blackbeautyroster.com";
  const [quizScore, setQuizScore] = useState(0);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const getQuizScore = () => {
    let correctCount = 0;
    const correctAnswers = quizData.questions.map((q) => q.correct);
    const answers = getLocalStorage("quiz_answers") || {};
    const answerValues = Object.values(answers);
    answerValues.forEach((answer, idx) =>
      correctAnswers[idx] === answer ? ++correctCount : null
    );

    const correctPercentage = (correctCount / questionsCount) * 100;
    return correctPercentage.toFixed();
  };

  const setQuizCompleted = () => {
    const quizMeta = {
      completed: true,
      completedDate: new Date().getTime(),
    };
    setLocalStorage("quiz_metadata", quizMeta);
  };
  useEffect(() => {
    const { completed } = getLocalStorage("quiz_metadata") || {};
    const answers = getLocalStorage("quiz_answers") || {};
    if (!completed && Object.keys(answers).length === questionsCount) {
      setQuizCompleted();
    }
    setQuizScore(getQuizScore());
  }, []);

  const sendResults = async (e) => {
    e.preventDefault();
    const quiz_answers = await getLocalStorage("quiz_answers");
    const quiz_metadata = await getLocalStorage("quiz_metadata");

    const req = await fetch(API_URL + "/quiz", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        companyName: e.target.companyName.value,
        quiz_answers,
        quiz_metadata,
        score: quizScore,
      }),
    });
    const res = await req.json();

    if (res) {
      setSubmitSuccess(true);
    }
  };

  return (
    <div className={styles.completed}>
      <div className={styles.header}>
        <h3 id={styles.bbrQuizTitle} className={styles.miniTitle}>
          BBR QUIZ
        </h3>
        <h3 className={styles.miniTitle}>
          Are You a Diverse Hair &amp; Makeup Ally?
        </h3>
      </div>
      <div className={styles.completedBody}>
        <h1 className={styles.completedBody__title_mobile}>
          Congrats!
          <br />
          You completed
          <br />
          the quiz
        </h1>
        <h1>Congrats! You completed the quiz</h1>
        <div className={styles.completedResultContainer}>
          <div>
            <p>Your Score</p>
            <h2>{quizScore}%</h2>
          </div>
          <div
            className={`${styles.outerElipsis} ${styles.outerElipsisOne}`}
          ></div>
          <div
            className={`${styles.outerElipsis} ${styles.outerElipsisTwo}`}
          ></div>
          <div
            className={`${styles.outerElipsis} ${styles.outerElipsisThree}`}
          ></div>
          <div
            className={`${styles.outerElipsis} ${styles.outerElipsisFour}`}
          ></div>
        </div>
        <form className={styles.emailInputForm} onSubmit={sendResults}>
          <p>Enter your email to see your full results</p>
          <input
            required
            name="email"
            type="email"
            placeholder="Your email"
          ></input>
          <input
            required
            name="companyName"
            type="text"
            placeholder="Company name"
          ></input>
          <button disabled={submitSuccess} type="submit">
            send me the results
          </button>
          {submitSuccess && (
            <p style={{ color: "#0fdb1a" }}>Quiz results successfully sent!</p>
          )}
          <div className={styles.shareContainer}>
            <p>SHARE MY RESULTS</p>
            <div className={styles.shareIcons}>
              <FacebookShareButton
                url={"https://quiz.blackbeautyroster.com/"}
                hashtag={"#blackbeautyroster"}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={
                  "Check the results of my BBR QUIZ! https://quiz.blackbeautyroster.com/"
                }
                hashtag={"#blackbeautyroster"}
              >
                <XIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={"https://quiz.blackbeautyroster.com/"}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <WhatsappShareButton url={"https://quiz.blackbeautyroster.com/"}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <TelegramShareButton url={"https://quiz.blackbeautyroster.com/"}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
          </div>
        </form>
        <img
          src={WaveCompleted}
          alt="Wave Completed Screen"
          className={styles.waveCompleted}
        />
      </div>
    </div>
  );
};

export default Completed;
