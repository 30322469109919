import React, { useState } from "react";
import styles from "./index.module.scss";
import Wave1 from "../../images/wave-1.svg";
import Wave2 from "../../images/wave-2.svg";
import Wave3 from "../../images/wave-3.svg";
import Wave4 from "../../images/wave-4.svg";
import Wave5 from "../../images/wave-5.svg";
import Wave6 from "../../images/wave-6.svg";
import Wave7 from "../../images/wave-7.svg";
import Wave8 from "../../images/wave-8.svg";
import Wave9 from "../../images/wave-9.svg";
import Wave10 from "../../images/wave-10.svg";
import Wave11 from "../../images/wave-11.svg";
import Wave12 from "../../images/wave-12.svg";
import Wave13 from "../../images/wave-13.svg";
import Wave14 from "../../images/wave-14.svg";
import { useLocation, useNavigate } from "react-router-dom";
import getLocalStorage from "../../helpers/getLocalStorage";
import setLocalStorage from "../../helpers/setLocalStorage";

const QuizQuestion = ({
  title,
  answers,
  questionNo,
  path,
  nextQuestionPath,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState(getLocalStorage("quiz_answers") || {});

  const answered = (answer) => {
    const answers = getLocalStorage("quiz_answers") || {};
    const newAnswers = { ...answers, [path]: answer.label };
    setLocalStorage("quiz_answers", newAnswers);
    setAnswerData(newAnswers);
    navigate(
      nextQuestionPath === "complete" ? "/completed" : `#${nextQuestionPath}`
    );
  };

  return (
    <div className={styles.quizQuestion} id={`${path}`}>
      <h1 className={styles.questionEnum}>-&nbsp;{questionNo}&nbsp;-</h1>
      <h1 className={styles.questionTitle}>{title}</h1>
      <div className={styles.answersWrapper}>
        <div className={styles.answersContainer}>
          {answers.map((answer, idx) => {
            const hasAnswered = answerData[path] === answer.label;
            console.log('hasAnswered', answerData[path], answer.label)
            const hasAnsweredStyle = hasAnswered ? styles.hasAnswered : null;
            return (
              <button
                key={idx}
                className={`${styles.answer} ${hasAnsweredStyle}`}
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  answered(answer);
                }}
              >
                {!answer.img && (
                  <p>{answer.label}</p>
                )}
                {answer.img && answer.dimensions && (
                  <img alt={`${answer.label} logo`} src={answer.img} style={{ width: answer.dimensions.width, height: answer.dimensions.height}} />
                )}
              </button>
            );
          })}
        </div>
        <div className={styles.footerWave}>
          {questionNo === "01" && <img src={Wave1} alt="Wave1" />}
          {questionNo === "02" && <img src={Wave2} alt="Wave2" />}
          {questionNo === "03" && <img src={Wave3} alt="Wave3" />}
          {questionNo === "04" && <img src={Wave4} alt="Wave4" />}
          {questionNo === "05" && <img src={Wave5} alt="Wave5" />}
          {questionNo === "06" && <img src={Wave6} alt="Wave6" />}
          {questionNo === "07" && <img src={Wave7} alt="Wave7" />}
          {questionNo === "08" && <img src={Wave8} alt="Wave8" />}
          {questionNo === "09" && <img src={Wave9} alt="Wave9" />}
          {questionNo === "10" && <img src={Wave10} alt="Wave10" />}
          {questionNo === "11" && <img src={Wave11} alt="Wave11" />}
          {questionNo === "12" && <img src={Wave12} alt="Wave12" />}
          {questionNo === "13" && <img src={Wave13} alt="Wave13" />}
          {questionNo === "14" && <img src={Wave14} alt="Wave14" />}
        </div>
      </div>
    </div>
  );
};

export default QuizQuestion;
