import React, { useEffect, useState } from "react";
import landingPageDesktop from "../images/landing-page.svg";
import landingPageMobile from "../images/landing-mobile.svg";
import { Link, useNavigate } from "react-router-dom";
import getLocalStorage from "../helpers/getLocalStorage";

const Landing = () => {
  const navigate = useNavigate();
  const [buttonLink, setButtonLink] = useState("quiz");
  useEffect(() => {
    const { completed } = getLocalStorage("quiz_metadata") || {};
    // Redirect to completed page if user already completed quiz
    if (completed) {
      // TODO: uncomment for prod
      // setButtonLink("completed")
      // navigate("completed")
    }
  }, []);
  return (
    <div className="landing flex items-center justify-center">
      <Link to={buttonLink}>
        <img
          style={{ maxWidth: "100vw", maxHeight: "100vh" }}
          src={landingPageDesktop}
          alt="Are You a Diverse Hair &amp; Makeup Ally? Start the quiz here!"
          className="hidden sm:block min-h-screen cursor-pointer"
        />

        <img
          style={{ width: "100vw", height: "90vh" }}
          src={landingPageMobile}
          alt="Are You a Diverse Hair &amp; Makeup Ally? Start the quiz here!"
          className="block sm:hidden cursor-pointer"
        />
      </Link>
    </div>
  );
};

export default Landing;
